.verticalContainer {
  display: flex;
  gap: 12px;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.inputSection {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.validationResult {
  gap: 8px;
  display: flex;
  align-items: center;
  color: var(--text-color-secondary);
}

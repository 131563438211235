.content {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.controls {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.map {
  width: 75%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.slider {
  width: 60%;
  padding: 8px;
}

.specialtyRow {
  min-width: 0;
  flex-shrink: 0;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  gap: 32px;
  font-family: var(--font-family);
  background-color: var(--surface-card);
  transition: border-radius 0.15s ease, box-shadow 0.15s ease,
    background-color 0.15s ease;
  color: var(--text-color);
  position: relative;
}

.rowContentSmall {
  width: 40px;
  display: flex;
  align-items: center;
}

.rowContent {
  width: 140px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.line {
  height: 1px;
  width: 100%;
  margin-left: -16px;
  margin-right: -16px;
  background-color: var(--surface-border);
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  overflow-y: auto;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.horizontalContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.horizontalContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header {
  font-size: 18px;
  font-weight: bold;
  margin-top: 8px;
}

.line {
  height: 1px;
  width: 600px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: var(--surface-border);
}

.searchContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
  gap: 8px;
  min-height: 0px;
}

.searchInput {
  width: 800px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-right: 20px;
  flex-shrink: 0;
}

.rowsContainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 1332px;
  min-height: 200px;
  flex-grow: 1;
  border-radius: 6px;
  overflow-y: hidden;
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0px;
  gap: 1px;
}

.subSearchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
}

.filterSortContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.chipContainer {
  display: flex;
  gap: 8px;
}

.multiSelect {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: transparent;
  padding: 0px;
  font-size: 13px;
  border-width: 0;
}

.multiSelect > div {
  height: 32px;
  width: 32px;
  color: var(--text-color);
}

.verticalContainer {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.horizontalContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.row {
  cursor: pointer;
}

.row:hover {
  border-radius: 4px;
  background: linear-gradient(
    to right,
    var(--highlight-bg),
    var(--surface-100)
  );
}
